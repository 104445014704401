import { Component, OnInit } from '@angular/core';
import { LibraryService } from '../shared/library.service';
import { environment as ENV } from '../../environments/environment.prod';
@Component({
	selector: 'app-public-procurement-policy',
	templateUrl: './public-procurement-policy.component.html',
	styleUrls: ['./public-procurement-policy.component.css']
})
export class PublicProcurementPolicyComponent implements OnInit {
  public baseUrl: string = ENV.BASE_URL;
  public Data: any;
  public lang:any = sessionStorage.getItem('lang');

  constructor(
    private libraryService: LibraryService,
	) {

	}


	ngOnInit() {
    this.libraryService.getPublicProcurementPolicy(this.lang).subscribe(result => {
      this.Data = result;
    });

  }

}
