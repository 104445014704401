import { Component, OnInit } from '@angular/core';
import { LibraryService } from '../shared/library.service';
import { environment as ENV } from '../../environments/environment.prod';
@Component({
	selector: 'app-vendor-information',
	templateUrl: './vendor-information.component.html',
	styleUrls: ['./vendor-information.component.css']
})
export class VendorInformationComponent implements OnInit {
  public baseUrl: string = ENV.BASE_URL;
  public venderInfoData: any;
  public lang:any = sessionStorage.getItem('lang');
  public isloded:boolean=false;
  dtOptions: any = {};

  constructor(
    private libraryService: LibraryService,
	) {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthMenu : [5, 10, 25],
      processing: true
    };
	}


	ngOnInit() {
    this.libraryService.getVendorInformation(this.lang).subscribe(result => {
      this.venderInfoData = result;
      this.isloded = true;
    });

  }

}
