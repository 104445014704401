import { Component, OnInit } from '@angular/core';
import { LibraryService } from '../shared/library.service';
import { environment as ENV } from '../../environments/environment.prod';
@Component({
	selector: 'app-press-release',
	templateUrl: './press-release.component.html',
	styleUrls: ['./press-release.component.css']
})
export class PressReleaseComponent implements OnInit {
  public baseUrl: string = ENV.BASE_URL;
  public lang:any = sessionStorage.getItem('lang');
  public PressReleaseData: any;
  public mousData: any;
  public isloded:boolean=false;
  public data:any[] = [];
  dtOptions: any = {};


  constructor(
    private libraryService: LibraryService,
	) {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthMenu : [5, 10, 25],
      processing: true
    };
	}


	ngOnInit() {

    this.libraryService.getPressReleaseData(this.lang).subscribe(result => {
      this.PressReleaseData = result;
      this.data.push(this.PressReleaseData);
      this.isloded = true;
    });

  }

}
