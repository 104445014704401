import { Component, OnInit } from '@angular/core';
import { LibraryService } from '../shared/library.service';
import { environment as ENV } from '../../environments/environment.prod';
@Component({
	selector: 'app-spvs',
	templateUrl: './spvs.component.html',
	styleUrls: ['./spvs.component.css']
})
export class SpvsComponent implements OnInit {
  public baseUrl: string = ENV.BASE_URL;
  public lang:any = sessionStorage.getItem('lang');
  public SpvsData: any;
  public SpvsCompanyData: any;

  constructor(
    private libraryService: LibraryService,
	) {

	}


	ngOnInit() {
    this.libraryService.getSpvs(this.lang).subscribe(result => {
      this.SpvsData = result;
      // console.log(result);
    });

    this.libraryService.getSpvs(this.lang).subscribe(result => {
      this.SpvsCompanyData = result;
      // console.log(result);
    });

  }

}
