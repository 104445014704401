import { Component, OnInit } from '@angular/core';
import { LibraryService } from '../shared/library.service';
import { environment as ENV } from '../../environments/environment.prod';
@Component({
	selector: 'app-contact-information',
	templateUrl: './contact-information.component.html',
	styleUrls: ['./contact-information.component.css']
})
export class ContactInformationComponent implements OnInit {
  public baseUrl: string = ENV.BASE_URL;
  public CorporateOfficeData: any;
  public PUIData: any;
  public ManagementDirectory: any;
  public BoardDirectors: any;
  public lang:any = sessionStorage.getItem('lang');
  public isloded:boolean=false;
  public isloded1:boolean=false;

  constructor(
    private libraryService: LibraryService,
	) {

	}


	ngOnInit() {
    this.libraryService.getCorporateOffice(this.lang).subscribe(result => {
      this.CorporateOfficeData = result;
    });

    this.libraryService.getPUIOffice(this.lang).subscribe(result => {
      this.PUIData = result;
      this.isloded = true;
    });

    this.libraryService.getManagementDirectory(this.lang).subscribe(result => {
      this.ManagementDirectory = result;
    });

    this.libraryService.getBoardDirectors(this.lang).subscribe(result => {
      this.BoardDirectors = result;
      console.log(result);

    });

  }

}
