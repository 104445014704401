import { Component, OnInit } from '@angular/core';
import { LibraryService } from '../shared/library.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { environment as ENV } from '../../environments/environment.prod';
@Component({
	selector: 'app-employee-login',
	templateUrl: './employee-login.component.html',
	styleUrls: ['./employee-login.component.css']
})
export class EmployeeLoginComponent implements OnInit {
  public baseUrl: string = ENV.BASE_URL;
  public lang:any = sessionStorage.getItem('lang');
  public loginId:any;
  public title:string;
  public loginDetailsData:any;
  constructor(
	private libraryService: LibraryService,
	private route: ActivatedRoute,
    private router: Router
	) {

	}


	ngOnInit() {
		let loginId = this.route.snapshot.paramMap.get('id');
		this.title = this.route.snapshot.paramMap.get('title');
		this.libraryService.getloginDetails(this.lang,loginId).subscribe(result => {
			this.loginDetailsData = result;
		  });
  }

}
