import { Component, OnInit } from '@angular/core';
import { LibraryService } from '../shared/library.service';
import { environment as ENV } from '../../environments/environment.prod';
import { Location, LocationStrategy } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
	selector: 'app-projects-completed',
	templateUrl: './projects-completed.component.html',
	styleUrls: ['./projects-completed.component.css']
})
export class ProjectsCompletedComponent implements OnInit {
  public baseUrl: string = ENV.BASE_URL;
  public siteUrl:any;
  public lang:any = sessionStorage.getItem('lang');
  public project_cat = this.route.snapshot.queryParams.project_cat;
  public projectImage: any;
  public ProjectsProgressCategoryData: any;
  loaded:boolean=false;
  filterdata:any[]=[];
  isactive:boolean=false;
  selectedIndex: any = this.project_cat!='undefined' && this.project_cat!='' && this.project_cat!=null? this.project_cat:'All';
  classanimation:string='';
  p: number = 1;
  constructor(
    private libraryService: LibraryService,
    private location: Location, 
    private locationStrategy: LocationStrategy,
    private route: ActivatedRoute,
    private router: Router
	) {
    this.siteUrl = this.locationStrategy.getBaseHref();
  }

  cat(lo,i){
    this.classanimation='';
    this.selectedIndex = i;
   this.projectImage=[]
   this.filterdata[0].forEach(element => {
     if(element.category==lo){
       this.projectImage.push(element);
       console.log(this.projectImage);
     }
     if(lo=='All'){
       this.selectedIndex='All';
      this.projectImage.push(element);
      console.log(this.projectImage);
    }
   });
   this.classanimation='animated zoomIn';
  }

  onClickSubmit(data){
    // console.log(data.searchValue);
    this.libraryService.getProjectsCompletedSearch(this.lang,data.searchValue).subscribe(result => {
      this.projectImage = result;
      this.filterdata.push(this.projectImage);
      this.loaded=true;
    });
  }

	ngOnInit() {    
    this.libraryService.getCategoryProjectsProgress(this.lang).subscribe(result => {
      this.ProjectsProgressCategoryData = result;
    });

    this.libraryService.getProjectsCompleted(this.lang).subscribe(result => {
      this.projectImage = result;
      this.filterdata.push(this.projectImage);
      this.loaded=true;

      //calling cat function on pageload to get projects category wise from query string
      this.cat(this.selectedIndex,this.selectedIndex);
    });
    
  }

}
