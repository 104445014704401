import { Component,ElementRef,HostListener, OnInit, ViewChild } from '@angular/core';
import { LibraryService } from '../shared/library.service';
import { environment as ENV } from '../../environments/environment.prod';
import { EmbedVideoService } from 'ngx-embed-video';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Lightbox, LightboxConfig, LightboxEvent, LIGHTBOX_EVENT } from 'ngx-lightbox';
import { Location, LocationStrategy } from '@angular/common';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit { 
  public bannerData: any;
  public baseUrl: string = ENV.BASE_URL;
  public basehref: string = ENV.BASE_HREF;
  public siteUrl:any;
  public whoWeAre:any;
  public managementSpeak:any;
  public managementSpeakVideo = [];
  yt_iframe_html: any;
  public importantAnnouncements:any;
  public projectHeading:any;
  public projectImage:any;
  public Investors:any;
  public rnvlNews:any;
  public PhotoGallery:any;
  public importantAnnouncementsList:any;
  public lang:any = sessionStorage.getItem('lang');
  public loaded:boolean=false;
  public loaded1:boolean=false;
  public scrol:boolean=false;
  config={
    auto:false
  };
  config1={
    auto:false
  };
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    autoplay:true,
    autoplayTimeout:10000,
    autoplayHoverPause:true,
    navSpeed: 600,
    navText: ['&#8249', '&#8250;'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      760: {
        items: 3
      },
      1000: {
        items: 4
      }
    },
    nav: true
  }
  public _albums: any[] = [];
  public _albums1: any[] = [];
  constructor(
    private libraryService: LibraryService,
    private embedService: EmbedVideoService,
    private _lightbox:Lightbox,
    private _lightboxEvent: LightboxEvent,
    private location: Location, 
    private locationStrategy: LocationStrategy
	) {
    this.siteUrl = this.locationStrategy.getBaseHref();
  }
 
  navtonews(){
    sessionStorage.setItem('navtonews',"true");
  }
  navtoAnousment(){
    sessionStorage.setItem('navtoall',"true");
  }
  open(i){
    this._albums.splice(0,100);
   this.projectImage[i].imgDetails.forEach(element => {
     let imgdetail=element;
     const src = this.baseUrl+'uploads/ProjectImages/'+ imgdetail;
     const thumb = this.baseUrl+'uploads/ProjectImages/'+ imgdetail;
     const caption = this.projectImage[i].heading;
     
     const album = {
        src: src,
        caption:caption,
        thumb: thumb,
     };
     this._albums.push(album);
    
   });
   this._lightbox.open(this._albums, 0);
  }
  open1(i){
    this._albums1.splice(0,100);
    this.PhotoGallery[0].PhotoGalleryDetails.forEach(element => {
      const src = this.baseUrl+'uploads/PhotoGallery/'+ element.img;
      const caption = element.title;
      const thumb=this.baseUrl+'uploads/PhotoGallery/'+ element.img;
      const album = {
         src: src,
         caption:caption,
         thumb:thumb,
         autoplay:true,
      };
      this._albums1.push(album); 
        
    });
    this._lightbox.open(this._albums1, i);
   }
  
  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    if(window.pageYOffset>=1900){
      this.config={
        auto:true
      };
    }
    if(window.pageYOffset>=400){
      this.config1={
        auto:true
      };
  }
  }
  trackBydata(index:number,data:any){
    return data.id;
  }
  trackBydata1(index:number,data:any){
    return data.id;
  }

  trackBydata2(index:number,data:any){
    return data.id;
  }  
	ngOnInit() {
    if (sessionStorage.getItem('navtonews') == "true") {
      setTimeout(() => {
        window.scrollTo(0, 2800);
      }, 2000);
      sessionStorage.setItem('navtonews', '');
    }
    if (sessionStorage.getItem('navtoall') == "true") {
      setTimeout(() => {
        window.scrollTo(0, 1000);
      }, 2000);
      sessionStorage.setItem('navtoall', '');
    }
    this.libraryService.getBanner(this.lang).subscribe(result => {
      this.bannerData = result;
      console.log(this.bannerData);
      var i=0;
      this.bannerData.forEach(element => {
        if(i==0){
            element.is_active="active";
        }
        i++;
        console.log(element);

      });
    });

    this.libraryService.getWhoWeAre(this.lang).subscribe(result => {
      this.whoWeAre = result;
    });

    this.libraryService.getManagementSpeak(this.lang).subscribe(result => {
      this.managementSpeak = result;
        this.managementSpeak.forEach(element => {
          this.yt_iframe_html = this.embedService.embed(element.video_link,{
            attr: { width: 560, height: 315 }
          });

          var ngxg = {
            id: element.id,
            heading: element.heading,
            title: element.title,
            ifram:this.yt_iframe_html
          };

        this.managementSpeakVideo.push(ngxg);
      });
    });


	this.libraryService.getImportantAnnouncements(this.lang).subscribe(result => {
    this.importantAnnouncements = result;
  });

  this.libraryService.getImportantAnnouncementsList(this.lang).toPromise().then(result => {
    this.importantAnnouncementsList = result;
    this.loaded=true;
  });

  this.libraryService.getProjectHeading(this.lang).subscribe(result => {
    this.projectHeading = result;
  });

  this.libraryService.getProjectImg(this.lang).toPromise().then(result => {
    this.projectImage = result;
    this.loaded1=true;
  });

  this.libraryService.getInvestors(this.lang).subscribe(result => {
    this.Investors = result;
  });

  this.libraryService.getRnvlNews(this.lang).subscribe(result => {    
      this.rnvlNews = result;
      console.log(this.rnvlNews);
      this.rnvlNews[0].newsDetails.forEach(element => {
        if(element.type == 'Img' && element.img != null && element.img != '') {
          var nameArr = element.img.split(',');
          element.img = nameArr[0];
        }else if(element.type == 'Video'){
          this.yt_iframe_html = this.embedService.embed(element.img,{
            attr: { width: 50, height: 50 }
          });
          element.img = this.yt_iframe_html;
        }
      });
  });

  this.libraryService.getPhotoGallery(this.lang).subscribe(result => {
    this.PhotoGallery = result; 
	});

  }

}
