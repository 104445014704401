import { Component, OnInit } from '@angular/core';
import { LibraryService } from '../shared/library.service';
import { environment as ENV } from '../../environments/environment.prod';
@Component({
	selector: 'app-active-tenders',
	templateUrl: './active-tenders.component.html',
	styleUrls: ['./active-tenders.component.css']
})
export class ActiveTendersComponent implements OnInit {
  public baseUrl: string = ENV.BASE_URL;
  public activeTenders: any;
  public submitTotalViews: any;
  public allActiveTenders: any;
  public counter: any = 1;
  public lang:any = sessionStorage.getItem('lang');
  public isloded:boolean=false;
  dtOptions: any = {};

  constructor(
    private libraryService: LibraryService,
	) {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthMenu : [5, 10, 25],
      processing: true,
      order:[[3, 'desc']]
    };
  }

  viewAllData(activeAllTenders){
    let  id = activeAllTenders.id;
    this.libraryService.submitTotalViews(id,this.lang).subscribe(result => {
      this.submitTotalViews = result;
      this.isloded = true;
    });

    this.libraryService.getTotalViews(id,this.lang).subscribe(result => {
      this.allActiveTenders = result;
      this.isloded = true;
    });
  }

	ngOnInit() {
    this.libraryService.getActiveTenders(this.lang).subscribe(result => {
      this.activeTenders = result;
      this.isloded = true;
    });
  }

}
