import { Component, OnInit } from '@angular/core';
import { LibraryService } from '../shared/library.service';
import { environment as ENV } from '../../environments/environment.prod';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
@Component({
	selector: 'app-policy',
	templateUrl: './policy.component.html',
	styleUrls: ['./policy.component.css']
})
export class PolicyComponent implements OnInit {
  public baseUrl: string = ENV.BASE_URL;
  public lang:any = sessionStorage.getItem('lang');
  public PolicyData: any;
  public PolicyDataPdf: any = [];
  yt_iframe_html: any;
  trustedDashboardUrl : SafeUrl;
  constructor(
    private libraryService: LibraryService,
    private sanitizer: DomSanitizer
	) {

	}


	ngOnInit() {
    this.libraryService.getPolicy(this.lang).subscribe(result => {
      this.PolicyData = result;
      this.PolicyData.forEach(element => {
        var url = this.baseUrl +"uploads/policy/"+ element.file;
        this.trustedDashboardUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        // console.log(url);
        // return false;
        this.yt_iframe_html = url;

        var ngxg = {
          id: element.id,
          pdfSrc:this.trustedDashboardUrl,
          pdf:element.file
        };

        this.PolicyDataPdf.push(ngxg);
    });



    });


  }

}
