import { Component, OnInit } from "@angular/core";
import { LibraryService } from "../shared/library.service";
import { environment as ENV } from "../../environments/environment.prod";
import { ActivatedRoute } from "@angular/router";
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from "@angular/platform-browser";
import { DatePipe } from '@angular/common'
// import { log } from "console";
@Component({
  selector: "app-investors",
  templateUrl: "./investors.component.html",
  styleUrls: ["./investors.component.css"],
})
export class InvestorsComponent implements OnInit {
  public baseUrl: string = ENV.BASE_URL;
  public basehref: string = ENV.basehref;
  public keyFiguresData: any;
  public importantNoticeAnnouncementsData: any;
  public importantNoticeAnnouncementsListData: any;
  public latestResultsData: any;
  public AnnualReportsData: any;
  public lang: any = sessionStorage.getItem("lang");
  trustedDashboardUrl: SafeUrl;
  public TermsData: any;
  public TermsDataPdf: any = [];
  public BoardCommitteesDataPdf: any;
  public FamiliarData: any;
  public BoardMeetingsData: any;
  public CompanyPoliciesData: any;
  public GeneralDisclosuresData: any;
  public InvestorInfoData: any;
  public FinancialResultsData: any;
  public FinancialResultsYearsData: any;
  public ShareholdingPatternData: any;
  public CorporateGovernanceData: any;
  public InvestorComplaintsData: any;
  public CompliancesReportData: any;
  public CompliancesDisclosureData: any;
  public YearAnnualReportsData: any;
  public YearBoardMeetingData: any;
  public YearGeneralDisclosuresData: any;
  public ShareholdingPatternYearsData: any;
  public CorporateGovernanceYearsData: any;
  public InvestorComplaintsYearsData: any;
  public CompliancesReportYearsData: any;
  public CompliancesDisclosureYearsData: any;
  public isloded1: boolean = false;
  public isloded2: boolean = false;
  public isloded3: boolean = false;
  public isloded4: boolean = false;
  public isloded5: boolean = false;
  public isloded6: boolean = false;
  public isloded7: boolean = false;
  public isloded8: boolean = false;
  public isloded9: boolean = false;
  public isloded10: boolean = false;
  public isloded11: boolean = false;
  public date :any;
  public latest_date:any;
  public financialDate:any;
  public islodedInvInfo: boolean = false;
  public committeesData: any;
  public committeesPdf =[];
  public investertab = "Dashboard";
  public compliancestab = "Financial_Results";
  public boardmeetingtab = "Board_Meetings";
  public independentdirectorstab = "Terms_and_Conditions";
  dtOptions: any = {};


  constructor(
    private libraryService: LibraryService,
    private sanitizer: DomSanitizer,
    public datepipe: DatePipe,
    private route: ActivatedRoute
  ) {
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 5,
      lengthMenu: [5, 10, 25],
      processing: true,
    };

    let BoardCommitteesUrl = this.baseUrl + "uploads/boardCommittees/Board-Committees.pdf";
    this.BoardCommitteesDataPdf =
          this.sanitizer.bypassSecurityTrustResourceUrl(BoardCommitteesUrl);
  }

  copydirectlink(section,querypara){
      // Copy the text inside the text field
      navigator.clipboard.writeText(this.basehref+section+querypara);
  }

  years(e) {
    let obj = e.target.value;
    this.AnnualReportsData = [];
    this.libraryService
      .getYearAnnualReportsSearch(this.lang, obj)
      .subscribe((result) => {
        this.AnnualReportsData = result;
        this.AnnualReportsData.forEach(element => {          
          if(element.created_on!=null && element.modified_on==null){
            this.date = new Date(element.created_on);
            this.latest_date = new Date();
            const diffTime = Math.abs(this.latest_date - this.date);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));  
            element['diff']=diffDays;
          }else if(element.created_on!=null && element.modified_on!=null){
              this.date = new Date(element.modified_on);
              this.latest_date = new Date();
              const diffTime = Math.abs(this.latest_date - this.date);
              const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));  
              element['diff']=diffDays;
          }  
            
        
       });
      });
  }

  boardMeetingYears(e) {
    let obj = e.target.value;
    this.BoardMeetingsData = [];
    this.libraryService
      .getYearBordMeetingSearch(this.lang, obj)
      .subscribe((result) => {
        this.BoardMeetingsData = result;
        this.BoardMeetingsData.forEach(element => {          
              this.date = new Date(element.created_on);
              this.latest_date = new Date();
              const diffTime = Math.abs(this.latest_date - this.date);
              const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
              element['diff']=diffDays;
            
           });
      });
  }

  YearGeneralDisclosures(e) {
    let obj = e.target.value;
    this.GeneralDisclosuresData = [];
    this.libraryService
      .getYearGeneralDisclosuresSearch(this.lang, obj)
      .subscribe((result) => {
        this.GeneralDisclosuresData = result;
        this.GeneralDisclosuresData.forEach(element => {          
          this.date = new Date(element.created_on);
          this.latest_date = new Date();
          const diffTime = Math.abs(this.latest_date - this.date);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
          element['diff']=diffDays;
        
       });
      });
  }

  FinancialResultsYears(e) {
    let obj = e.target.value;
    this.FinancialResultsData = [];
    this.libraryService
      .getFinancialResultsYearsSearch(this.lang, obj)
      .subscribe((result) => {
        this.FinancialResultsData = result;
        this.FinancialResultsData.forEach(element => {   
        if(element.created_on!=null && element.modified_on==null){
          this.date = new Date(element.created_on);
          this.latest_date = new Date();
          const diffTime = Math.abs(this.latest_date - this.date);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));  
          element['diff']=diffDays;
        }else if(element.created_on!=null && element.modified_on!=null){
            this.date = new Date(element.modified_on);
            this.latest_date = new Date();
            const diffTime = Math.abs(this.latest_date - this.date);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));  
            element['diff']=diffDays;
        }  
          
       });
      });
  }

  ShareholdingPatternYears(e) {
    let obj = e.target.value;
    this.ShareholdingPatternData = [];
    this.libraryService
      .getShareholdingPatternYearsSearch(this.lang, obj)
      .subscribe((result) => {
        this.ShareholdingPatternData = result;
        this.ShareholdingPatternData.forEach(element => {          
          if(element.created_on!=null && element.modified_on==null){
            this.date = new Date(element.created_on);
            this.latest_date = new Date();
            const diffTime = Math.abs(this.latest_date - this.date);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));  
            element['diff']=diffDays;
          }else if(element.created_on!=null && element.modified_on!=null){
              this.date = new Date(element.modified_on);
              this.latest_date = new Date();
              const diffTime = Math.abs(this.latest_date - this.date);
              const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));  
              element['diff']=diffDays;
          }  
        
       });    
      });
  }

  CorporateGovernanceYears(e) {
    let obj = e.target.value;
    this.CorporateGovernanceData = [];
    this.libraryService
      .getCorporateGovernanceYearsSearch(this.lang, obj)
      .subscribe((result) => {
        this.CorporateGovernanceData = result;
        this.CorporateGovernanceData.forEach(element => {          
          this.date = new Date(element.created_on);
          this.latest_date = new Date();
          const diffTime = Math.abs(this.latest_date - this.date);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));  
          element['diff']=diffDays;
        
       });
      });
  }

  InvestorComplaintsYears(e) {
    let obj = e.target.value;
    this.InvestorComplaintsData = [];
    this.libraryService
      .getInvestorComplaintsYearsSearch(this.lang, obj)
      .subscribe((result) => {
        this.InvestorComplaintsData = result;
        this.InvestorComplaintsData.forEach(element => {          
          this.date = new Date(element.created_on);
          this.latest_date = new Date();
          const diffTime = Math.abs(this.latest_date - this.date);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
          element['diff']=diffDays;
        
       });
      });
  }

  CompliancesReportYears(e) {
    let obj = e.target.value;
    this.CompliancesReportData = [];
    this.libraryService
      .getCompliancesReportYearsSearch(this.lang, obj)
      .subscribe((result) => {
        this.CompliancesReportData = result;
        this.CompliancesReportData.forEach(element => {          
          this.date = new Date(element.created_on);
          this.latest_date = new Date();
          const diffTime = Math.abs(this.latest_date - this.date);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
          element['diff']=diffDays;
        
       });

      });
  }

  CompliancesDisclosureYears(e) {
    let obj = e.target.value;
    this.CompliancesDisclosureData = [];
    this.libraryService
      .getCompliancesDisclosureYearsSearch(this.lang, obj)
      .subscribe((result) => {
        this.CompliancesDisclosureData = result;
        this.CompliancesDisclosureData.forEach(element => {          
          this.date = new Date(element.created_on);
          this.latest_date = new Date();
          const diffTime = Math.abs(this.latest_date - this.date);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
          element['diff']=diffDays;
        
       });
      });
  }

  ngOnInit() {
    this.investertab = this.route.snapshot.queryParams.investertab == undefined?"Dashboard":this.route.snapshot.queryParams.investertab;
    this.compliancestab = this.route.snapshot.queryParams.compliancestab == undefined?"Financial_Results":this.route.snapshot.queryParams.compliancestab;
    this.boardmeetingtab = this.route.snapshot.queryParams.boardmeetingtab == undefined?"Board_Meetings":this.route.snapshot.queryParams.boardmeetingtab;
    this.independentdirectorstab = this.route.snapshot.queryParams.independentdirectorstab == undefined?"Terms_and_Conditions":this.route.snapshot.queryParams.independentdirectorstab;
    this.libraryService
      .getCompliancesDisclosureYears(this.lang)
      .subscribe((result) => {
        this.CompliancesDisclosureYearsData = result;
        if (this.CompliancesDisclosureYearsData.length > 0) {
          let year = this.CompliancesDisclosureYearsData[0].year;
          this.libraryService
            .getCompliancesDisclosure(this.lang, year)
            .subscribe((result) => {
              this.CompliancesDisclosureData = result;
              this.isloded6 = true;
              this.CompliancesDisclosureData.forEach(element => {          
                this.date = new Date(element.created_on);
                this.latest_date = new Date();
                const diffTime = Math.abs(this.latest_date - this.date);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                element['diff']=diffDays;
              
             });
            });
        }
      });

    this.libraryService
      .getCompliancesReportYears(this.lang)
      .subscribe((result) => {
        this.CompliancesReportYearsData = result;
        if (this.CompliancesReportYearsData.length > 0) {
          let year = this.CompliancesReportYearsData[0].year;

          this.libraryService
            .getCompliancesReport(this.lang, year)
            .subscribe((result) => {
              this.CompliancesReportData = result;
              this.isloded5 = true;
              this.CompliancesReportData.forEach(element => {          
                this.date = new Date(element.created_on);
                this.latest_date = new Date();
                const diffTime = Math.abs(this.latest_date - this.date);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                element['diff']=diffDays;
              
             });
            });
        }
      });

    this.libraryService
      .getInvestorComplaintsYears(this.lang)
      .subscribe((result) => {
        this.InvestorComplaintsYearsData = result;
        if (this.InvestorComplaintsYearsData.length > 0) {
          let year = this.InvestorComplaintsYearsData[0].year;

          this.libraryService
            .getInvestorComplaints(this.lang, year)
            .subscribe((result) => {
              this.InvestorComplaintsData = result;
              this.isloded4 = true;
              this.InvestorComplaintsData.forEach(element => {          
                this.date = new Date(element.created_on);
                this.latest_date = new Date();
                const diffTime = Math.abs(this.latest_date - this.date);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                element['diff']=diffDays;
              
             });
            });
        }
      });

    this.libraryService
      .getCorporateGovernanceYears(this.lang)
      .subscribe((result) => {
        this.CorporateGovernanceYearsData = result;
        if (this.CorporateGovernanceYearsData.length > 0) {
          let year = this.CorporateGovernanceYearsData[0].year;

          this.libraryService
            .getCorporateGovernance(this.lang, year)
            .subscribe((result) => {
              this.CorporateGovernanceData = result;
              this.isloded3 = true;
              this.CorporateGovernanceData.forEach(element => {          
                this.date = new Date(element.created_on);
                this.latest_date = new Date();
                const diffTime = Math.abs(this.latest_date - this.date);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));  
                element['diff']=diffDays;
              
             });
            });
        }
      });

    this.libraryService
      .getShareholdingPatternYears(this.lang)
      .subscribe((result) => {
        this.ShareholdingPatternYearsData = result;
        if (this.ShareholdingPatternYearsData.length > 0) {
          let year = this.ShareholdingPatternYearsData[0].year;

          this.libraryService
            .getShareholdingPattern(this.lang, year)
            .subscribe((result) => {
              this.ShareholdingPatternData = result;
              this.isloded2 = true;
              this.ShareholdingPatternData.forEach(element => {  
                if(element.created_on!=null && element.modified_on==null){
                  console.log(element.created_on);
                  
                  this.date = new Date(element.created_on);
                  this.latest_date = new Date();
                  const diffTime = Math.abs(this.latest_date - this.date);
                  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));  
                  element['diff']=diffDays;
                }else if(element.created_on!=null && element.modified_on!=null){
                    this.date = new Date(element.modified_on);
                    this.latest_date = new Date();
                    const diffTime = Math.abs(this.latest_date - this.date);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));  
                    element['diff']=diffDays;
                }  
              
             });             
            });

        }
      });

    this.libraryService
      .getFinancialResultsYears(this.lang)
      .subscribe((result) => {
        this.FinancialResultsYearsData = result;
        if (this.FinancialResultsYearsData.length > 0) {
          let year = this.FinancialResultsYearsData[0].year;
          let currentDate: Date = new Date();
          this.libraryService
            .getFinancialResults(this.lang, year)
            .subscribe((result) => {
              this.FinancialResultsData = result;
              this.isloded1 = true;
              this.FinancialResultsData.forEach(element => {          
                if(element.created_on!=null && element.modified_on==null){
                  this.date = new Date(element.created_on);
                  this.latest_date = new Date();
                  const diffTime = Math.abs(this.latest_date - this.date);
                  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));  
                  element['diff']=diffDays;
                }else if(element.created_on!=null && element.modified_on!=null){
                    this.date = new Date(element.modified_on);
                    this.latest_date = new Date();
                    const diffTime = Math.abs(this.latest_date - this.date);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));  
                    element['diff']=diffDays;
                }  
             });
            });
        }
      });

    this.libraryService.getYearBordMeeting(this.lang).subscribe((result) => {
      this.YearBoardMeetingData = result;
      if (this.YearBoardMeetingData.length > 0) {
        let year = this.YearBoardMeetingData[0].year;

        this.libraryService
          .getBoardMeetings(this.lang, year)
          .subscribe((result) => {
            this.BoardMeetingsData = result;
            this.isloded9 = true;
            this.BoardMeetingsData.forEach(element => {          
              this.date = new Date(element.created_on);
              this.latest_date = new Date();
              const diffTime = Math.abs(this.latest_date - this.date);
              const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
              element['diff']=diffDays;
            
           });
           
          });
      }
    });

    this.libraryService
      .getYearGeneralDisclosures(this.lang)
      .subscribe((result) => {
        this.YearGeneralDisclosuresData = result;
        if (this.YearGeneralDisclosuresData.length > 0) {
          let year = this.YearGeneralDisclosuresData[0].year;
          this.libraryService
            .getGeneralDisclosures(this.lang, year)
            .subscribe((result) => {
              this.GeneralDisclosuresData = result;
              this.isloded10 = true;
              this.GeneralDisclosuresData.forEach(element => {          
                this.date = new Date(element.created_on);
                this.latest_date = new Date();
                const diffTime = Math.abs(this.latest_date - this.date);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                element['diff']=diffDays;
              
             });
            });
        }
      });

    this.libraryService.getKeyFigures(this.lang).subscribe((result) => {
      this.keyFiguresData = result;
      // console.log(this.keyFiguresData);
      
    });

    this.libraryService
      .getImportantNoticeAnnouncements(this.lang)
      .subscribe((result) => {
        this.importantNoticeAnnouncementsData = result;
      });

    this.libraryService
      .getImportantNoticeAnnouncementsList(this.lang)
      .subscribe((result) => {
        this.importantNoticeAnnouncementsListData = result;
      });

    this.libraryService.getLatestResults(this.lang).subscribe((result) => {
      this.latestResultsData = result;
    });

    this.libraryService.getYearAnnualReports(this.lang).subscribe((result) => {
      this.YearAnnualReportsData = result;
      if (this.YearAnnualReportsData.length > 0) {
        let year = this.YearAnnualReportsData[0].year;

        this.libraryService
          .getAnnualReports(this.lang, year)
          .subscribe((result) => {
            this.AnnualReportsData = result;
            this.isloded7 = true;
            this.AnnualReportsData.forEach(element => {          
              if(element.created_on!=null && element.modified_on==null){
                this.date = new Date(element.created_on);
                this.latest_date = new Date();
                const diffTime = Math.abs(this.latest_date - this.date);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));  
                element['diff']=diffDays;
              }else if(element.created_on!=null && element.modified_on!=null){
                  this.date = new Date(element.modified_on);
                  this.latest_date = new Date();
                  const diffTime = Math.abs(this.latest_date - this.date);
                  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));  
                  element['diff']=diffDays;
              }  
                
            
           });
          });
      }
    });

    this.libraryService.getTerms(this.lang).subscribe((result) => {
      this.TermsData = result;

      this.TermsData.forEach((element) => {
        var url = this.baseUrl + "uploads/terms/" + element.file;
        this.trustedDashboardUrl =
          this.sanitizer.bypassSecurityTrustResourceUrl(url);
        // console.log(url);
        // return false;

        var ngxg = {
          id: element.id,
          header_text: element.header_text,
          pdfSrc: this.trustedDashboardUrl,
          pdf: element.file,
        };

        this.TermsDataPdf.push(ngxg);
      });
    });

    this.libraryService.getFamiliar(this.lang).subscribe((result) => {
      this.FamiliarData = result;
      this.isloded8 = true;
      this.FamiliarData.forEach(element => {          
        this.date = new Date(element.created_on);
        this.latest_date = new Date();
        const diffTime = Math.abs(this.latest_date - this.date);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        element['diff']=diffDays;
      
     });
    });

    this.libraryService.getCompanyPolicies(this.lang).subscribe((result) => {
      this.CompanyPoliciesData = result;
      this.isloded11 = true;
      this.CompanyPoliciesData.forEach(element => {          
        this.date = new Date(element.created_on);
        this.latest_date = new Date();
        const diffTime = Math.abs(this.latest_date - this.date);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        element['diff']=diffDays;
      
     });
    });

    this.libraryService.getInvestorInfoData(this.lang).subscribe((result) => {
      this.InvestorInfoData = result;
      this.islodedInvInfo = true;
    });
    this.libraryService.getBoardCommittees(this.lang).subscribe(result => {
      this.committeesData = result;
      // console.log(this.committeesData);
      this.committeesData.forEach(element => {
              console.log(element);
              
              var url = this.baseUrl +"uploads/boardmeeting/"+ element.file;
              console.log(url);
              
              this.BoardCommitteesDataPdf = this.sanitizer.bypassSecurityTrustResourceUrl(url);              
              var ngxy = {
                ifram:this.BoardCommitteesDataPdf
              };
              this.committeesPdf.push(ngxy);
              console.log(this.committeesPdf);
          })
      
      
    });
  
  }
}
