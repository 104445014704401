import { Component, OnInit } from '@angular/core';
import { LibraryService } from '../shared/library.service';
import { environment as ENV } from '../../environments/environment.prod';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { EmbedVideoService } from 'ngx-embed-video';
// import { log } from 'console';


@Component({
	selector: 'app-about-us',
	templateUrl: './about-us.component.html',
	styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  public baseUrl: string = ENV.BASE_URL;
  public lang:any = sessionStorage.getItem('lang');
  public aboutUsData: any;
  public ourMissionAndVisionData: any;
  public OurObjectivesData: any;
  public mousData: any;
  public loaderToggle: any;
  public brandeData :any;
  trustedDashboardUrl : SafeUrl;
  yt_iframe_html: any;
  yz_iframe_html: any;
  public ourbrandVideo = [];
  public ourbrandPdf= [];
  constructor(
    private libraryService: LibraryService,
    private sanitizer: DomSanitizer,
    private embedService: EmbedVideoService,
	) {

	}


	ngOnInit() {
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.loaderToggle = 'hide';
    }, 10000);

    var url = "";
    if(this.lang == 'en'){
      url = this.baseUrl + "uploads/aboutus/RVNL Book - 17yrs of Journey.pdf";
    }else{
      url = this.baseUrl + "uploads/aboutus/RVNL Book - 17yrs of Journey.pdf";
    }
    this.trustedDashboardUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);

    this.libraryService.getAboutUs(this.lang).subscribe(result => {
      this.aboutUsData = result;
    });

    this.libraryService.getOurMissionAndVisionData(this.lang).subscribe(result => {
      this.ourMissionAndVisionData = result;
    });

    this.libraryService.getOurObjectivesData(this.lang).subscribe(result => {
      this.OurObjectivesData = result;
    });

    this.libraryService.getmousData(this.lang).subscribe(result => {
      this.mousData = result;
    });
    this.libraryService.getBrands(this.lang).subscribe(result => {
      this.brandeData = result;
      // console.log(this.brandeData);
      this.brandeData.forEach(element => {
        if(element.type=='youtube') {
            this.yt_iframe_html = this.embedService.embed(element.video_link,{
              attr: { width: 560, height: 315 }
            });
            var ngxg = {
              id: element.id,
              heading: element.type,
              title: element.title,
              ifram:this.yt_iframe_html
            };
            this.ourbrandVideo.push(ngxg);
        }else{
              var url = this.baseUrl +"uploads/ourbrands/"+ element.video_link;
              this.trustedDashboardUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
              // this.yz_iframe_html = url
              // console.log(this.trustedDashboardUrl);
              
              var ngxy = {
                id: element.id,
                heading: element.type,
                title: element.title,
                ifram:this.trustedDashboardUrl
              };
              this.ourbrandPdf.push(ngxy);
            }
          });
    // console.log(this.ourbrandPdf);
    
      });

  }

}
