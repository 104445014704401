import { Component, OnInit, Compiler } from '@angular/core';
import { LibraryService } from '../shared/library.service';
import { environment as ENV } from '../../environments/environment.prod';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { EmbedVideoService } from 'ngx-embed-video';

@Component({
	selector: 'app-project-details',
	templateUrl: './project-details.component.html',
	styleUrls: ['./project-details.component.css']
})
export class ProjectDetailsComponent implements OnInit {
  public baseUrl: string = ENV.BASE_URL;
  public lang:any = sessionStorage.getItem('lang');
  public ProjectDetailsData: any;
  public RelatedData:any;
  public finalRelatedData:any[]=[];
  public heading:any;
  public imgDetails:any;
  public description:any;
  public restdescription1:any;
  public restdescription2:any;
  public category:any;
  public project_type:any;
  public date:any;
  public type:any;
  public yt_iframe_html: any;
  imageObject: any[] = [];
  loaded:boolean=false;
  p: number = 1;
  constructor(
    private libraryService: LibraryService,
    private embedService: EmbedVideoService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private _compiler:Compiler
	) {
    this._compiler.clearCache();
	}


	ngOnInit() {
    let projectId = this.route.snapshot.paramMap.get('id');
    let category = this.route.snapshot.paramMap.get('category');
    this.libraryService.getProjectDetails(this.lang,projectId).subscribe(result => {
      this.ProjectDetailsData = result; 
      this.type = result.type;
      console.log(this.type);
      if(this.ProjectDetailsData.type == 'Video'){
        this.yt_iframe_html = this.embedService.embed(this.ProjectDetailsData.video_link,{
          attr: { width: 50, height: 400 }
        });
      }
      console.log(this.yt_iframe_html);
      this.heading = result.heading;

      this.imgDetails = result.imgDetails;
      this.description = result.description;
      this.restdescription1=this.description.substring(0,4000); 
      this.restdescription2=this.description.substring(4386,this.description.length);
      this.category = result.category;
      this.project_type = result.project_type;
      this.date = result.created_on;
      this.imgDetails.forEach(element => {
        const image = this.baseUrl+'uploads/ProjectImages/'+element;
      const thumbImage=this.baseUrl+'uploads/ProjectImages/'+element;
      const album = {
        image:image,
        thumbImage:thumbImage
      };
      this.imageObject.push(album);
     });
    });

    this.libraryService.getRelatedProject(this.lang,category).subscribe(result => {
      this.RelatedData = result;
      this.loaded=true;
      // console.log(this.ProjectDetailsData);
      // console.log(this.RelatedData);
      this.RelatedData.forEach(element => {
        if(element.id!=this.RelatedData.id){
          this.finalRelatedData.push(element);
        }
      });

    });

  }

}
