import { Component, OnInit } from '@angular/core';
import { LibraryService } from '../shared/library.service';
import { environment as ENV } from '../../environments/environment.prod';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';
import { FormGroup, FormControl } from '@angular/forms';
@Component({
	selector: 'app-photo-gallery',
	templateUrl: './photo-gallery.component.html',
	styleUrls: ['./photo-gallery.component.css']
})
export class PhotoGalleryComponent implements OnInit {
  public baseUrl: string = ENV.BASE_URL;
  public lang:any = sessionStorage.getItem('lang');
  public PhotoGalleryData: any;
  public PhotoCategoryData: any;
  loded:boolean=false;
  loded1:boolean=false;
  public _albums: any[] = [];
  filterdata:any[]=[];
  isactive:boolean=false;
  selectedIndex: number =-1;
  classanimation:string='';
  constructor(
    private libraryService: LibraryService,
    private _lightbox:Lightbox,
    private _lightboxConfig: LightboxConfig
	) {

	}
  cat(lo,i){
    this.classanimation='';
    this.selectedIndex = i;
   this.PhotoGalleryData=[]
   this.filterdata[0].forEach(element => {
     if(element.category==lo){
       this.PhotoGalleryData.push(element);
     }
     if(lo=='All'){
       this.selectedIndex=-1;
      this.PhotoGalleryData.push(element);
    }
   });
   this.classanimation='animated zoomIn';
  }

  onClickSubmit(data){
    // console.log(data.searchValue);
    this.libraryService.getPhotoGalleryAllSearch(this.lang,data.searchValue).subscribe(result => {
      this.PhotoGalleryData = result;
      this.filterdata.push(this.PhotoGalleryData);
      this.loded=true;
    });
  }


	ngOnInit() {
    this.libraryService.getCategoryPhotoGallery(this.lang).subscribe(result => {
      this.PhotoCategoryData = result;
      this.loded1=true;
    });

    this.libraryService.getPhotoGalleryAll(this.lang).subscribe(result => {
      this.PhotoGalleryData = result;
      this.filterdata.push(this.PhotoGalleryData);
      this.loded=true;
    });
  }
  open(i){
    this._albums.splice(0,100);
   this.PhotoGalleryData[i].album.forEach(element => {
     let imgdetail=element;
     const src = this.baseUrl+'uploads/PhotoGallery/'+ imgdetail.img;
     const caption =  imgdetail.caption;
     const album = {
        src: src,
        caption:caption
     };
     this._albums.push(album);
     
   });
   this._lightbox.open(this._albums, 0);
  }
}
