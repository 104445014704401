import { Component, OnInit } from '@angular/core';
import { LibraryService } from '../shared/library.service';
import { environment as ENV } from '../../environments/environment.prod';
@Component({
	selector: 'app-job',
	templateUrl: './job.component.html',
	styleUrls: ['./job.component.css']
})
export class JobComponent implements OnInit {
  public baseUrl: string = ENV.BASE_URL;
  public lang:any = sessionStorage.getItem('lang');
  public JobData: any;
  public isloded:boolean=false;
  dtOptions: any = {};
  public CarrerData: any;
  public date :any;
  public latest_date:any;

  constructor(
    private libraryService: LibraryService,
	) {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthMenu : [5, 10, 25],
      processing: true
    };
	}

  ngOnInit() {
    this.libraryService.getJobData(this.lang).subscribe(result => {
      this.JobData = result;
      this.JobData.forEach(element => {          
        this.date = new Date(element.created_on);
        this.latest_date = new Date();
        const diffTime = Math.abs(this.latest_date - this.date);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));  
        element['diff']=diffDays;
        console.log(diffDays);
        
      
     });
      this.isloded = true;
    });

  }

}
