// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --configuration production` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// "node_modules/jquery/dist/jquery.min.js",
// "baseHref": "/", use all root directive
// "baseHref": "https://rvnl.org/RVNL/",
const IS_PRODUCTION = true;
const PRODUCTION_BASE_URL = 'https://rvnl.org/RVNL_cms/';
// const PRODUCTION_BASE_URL = 'https://staging.rvnl.org/RVNL_cms/';

const DEVELOPMENT_BASE_URL = 'http://localhost/rvnl_stg/RVNL/';

const BASE_HREF = "https://rvnl.org/";
// const BASE_HREF = "https://staging.rvnl.org/";

const Config = {
	ENV: IS_PRODUCTION ? 'production' : 'development',
	production: {
		BASE_URL: PRODUCTION_BASE_URL,
		apiURL: PRODUCTION_BASE_URL + 'frontend/',
		production: IS_PRODUCTION,
		basehref:BASE_HREF
	},

	development: {
		BASE_URL: DEVELOPMENT_BASE_URL,
		apiURL: DEVELOPMENT_BASE_URL + 'frontend/',
		production: IS_PRODUCTION,
		basehref:BASE_HREF
	}
}

export const environment = {
	...Config[Config.ENV]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
