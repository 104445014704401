import { Component, OnInit } from '@angular/core';
import { LibraryService } from '../shared/library.service';
import { environment as ENV } from '../../environments/environment.prod';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
	selector: 'app-rti',
	templateUrl: './rti.component.html',
	styleUrls: ['./rti.component.css']
})
export class RtiComponent implements OnInit {
  public baseUrl: string = ENV.BASE_URL;
  public lang:any = sessionStorage.getItem('lang');
  public RtiData: any = [];
  public RtiPdfData: any;
  public RtiPiosData: any;
  trustedDashboardUrl : SafeUrl;
  constructor(
    private libraryService: LibraryService,
    private sanitizer: DomSanitizer
	) {

	}


	ngOnInit() {
    this.libraryService.getRti(this.lang).subscribe(result => {
      this.RtiPdfData = result;
      this.RtiPdfData.forEach(element => {
          var url = this.baseUrl +"uploads/rti_act/"+ element.act_file;
          this.trustedDashboardUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);


          var ngxg = {
            id: element.id,
            no_applicants: element.no_applicants,
            app_replied: element.app_replied,
            rti_disclouser: element.rti_disclouser,
            pdfSrc:this.trustedDashboardUrl,
            pdf:element.file
          };

          this.RtiData.push(ngxg);
      });
    });

    this.libraryService.getRtiPios(this.lang).subscribe(result => {
      this.RtiPiosData = result;
    });

  }

}
