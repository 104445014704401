import { Component, OnInit } from '@angular/core';
import { LibraryService } from '../shared/library.service';
import { environment as ENV } from '../../environments/environment.prod';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
@Component({
	selector: 'app-management-board-details',
	templateUrl: './management-board-details.component.html',
	styleUrls: ['./management-board-details.component.css']
})
export class ManagementBoardDetailsComponent implements OnInit {
  public baseUrl: string = ENV.BASE_URL;
  public ManagementBoardDetailsData: any;
  public lang:any = sessionStorage.getItem('lang');

  public name:any;
  public designation:any;
  public photo:any;
  public desc:any;

  constructor(
    private libraryService: LibraryService,
    private route: ActivatedRoute,
    private router: Router
	) {

	}

	ngOnInit() {
    let managementBoardId = this.route.snapshot.paramMap.get('id');
    this.libraryService.getManagementBoardDetails(this.lang,managementBoardId).subscribe(result => {
      this.ManagementBoardDetailsData = result;

      this.name = result.name;
      this.designation = result.designation;
      this.photo = result.photo;
      this.desc = result.desc;
    });

  }

}
