import { Component, HostListener, OnInit } from '@angular/core';
import { LibraryService } from '../shared/library.service';
import { environment as ENV } from '../../environments/environment.prod';
import { Location, LocationStrategy } from '@angular/common';
import { EmbedVideoService } from 'ngx-embed-video';

@Component({
	selector: 'app-news',
	templateUrl: './news.component.html',
	styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  public baseUrl: string = ENV.BASE_URL;
  public siteUrl:any;
  public lang:any = sessionStorage.getItem('lang');
  public NewsData: any;
  public yt_iframe_html: any;
  scroolpos:number;
  p: number = 1;
  constructor(
    private libraryService: LibraryService,
    private location: Location, 
    private embedService: EmbedVideoService,
    private locationStrategy: LocationStrategy
	) {
    this.siteUrl = this.locationStrategy.getBaseHref();
	}
  trackBydata(index:number,data:any){
    return data.id;
  }

	ngOnInit() {
    this.libraryService.getNews(this.lang).subscribe(result => {
      this.NewsData = result;  
      console.log(this.NewsData);  
      this.NewsData.forEach(element => {
        if(element.type == 'Img' && element.img != null && element.img != '') {
          var nameArr = element.img.split(',');
          element.img = nameArr[0];
        }else if(element.type == 'Video'){
          this.yt_iframe_html = this.embedService.embed(element.img,{
            attr: { width: 560, height: 250 }
          });
          element.img = this.yt_iframe_html;
        }
      });  
    });
 setTimeout(() => {
        window.scrollTo(0, parseFloat(sessionStorage.getItem("pos")));
        sessionStorage.setItem("pos","");
      }, 2000);
  }
  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    sessionStorage.setItem("pos",window.pageYOffset.toString())
  //this.scroolpos= window.pageYOffset;
  }
}
