import { Component, OnInit } from '@angular/core';
import { LibraryService } from '../shared/library.service';
import { environment as ENV } from '../../environments/environment.prod';
@Component({
	selector: 'app-csr',
	templateUrl: './csr.component.html',
	styleUrls: ['./csr.component.css']
})
export class CsrComponent implements OnInit {
  public baseUrl: string = ENV.BASE_URL;
  public lang:any = sessionStorage.getItem('lang');
  public CsrData: any;
  public CsrstoriesData: any;
  public isloded:boolean=false;
  dtOptions: any = {};

  constructor(
    private libraryService: LibraryService,
	) {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthMenu : [5, 10, 25],
      processing: true
    };
	}


	ngOnInit() {
    this.libraryService.getCsrstories(this.lang).subscribe(result => {
      this.CsrstoriesData = result;
    });

    this.libraryService.getCsr(this.lang).subscribe(result => {
      this.CsrData = result;
      this.isloded = true;
    });

  }

}
