import { Component, OnInit, Compiler } from '@angular/core';
import { LibraryService } from '../shared/library.service';
import { environment as ENV } from '../../environments/environment.prod';
import { Location, LocationStrategy } from '@angular/common';

@Component({
	selector: 'app-projects-progress',
	templateUrl: './projects-progress.component.html',
	styleUrls: ['./projects-progress.component.css']
})
export class ProjectsProgressComponent implements OnInit {
  public baseUrl: string = ENV.BASE_URL;
  public siteUrl:any;
  public lang:any = sessionStorage.getItem('lang');
  public projectImage: any;
  public ProjectsProgressCategoryData: any;
  loaded:boolean=false;
  filterdata:any[]=[];
  isactive:boolean=false;
  selectedIndex: number =-1;
  classanimation:string='';
  p: number = 1;
  constructor(
    private libraryService: LibraryService,
    private _compiler:Compiler,
    private location: Location, 
    private locationStrategy: LocationStrategy
	) {
    this.siteUrl = this.locationStrategy.getBaseHref();
    this._compiler.clearCache();
	}

  cat(lo,i){
    this.classanimation='';
    this.selectedIndex = i;
   this.projectImage=[]
   this.filterdata[0].forEach(element => {
     if(element.category==lo){
       this.projectImage.push(element);
     }
     if(lo=='All'){
       this.selectedIndex=-1;
      this.projectImage.push(element);
    }
   });
   this.classanimation='animated zoomIn';
  }

  onClickSubmit(data){
    // console.log(data.searchValue);
    this.libraryService.getProjectsProgressSearch(this.lang,data.searchValue).subscribe(result => {
      this.projectImage = result;
      this.filterdata.push(this.projectImage);
      this.loaded=true;
    });
  }

	ngOnInit() {
    this.libraryService.getCategoryProjectsProgress(this.lang).subscribe(result => {
      this.ProjectsProgressCategoryData = result;
    });

    this.libraryService.getProjectsProgress(this.lang).subscribe(result => {
      this.projectImage = result;
      this.filterdata.push(this.projectImage);
      this.loaded=true;
    });
  }

}
