import { BrowserModule,HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgxPaginationModule} from 'ngx-pagination'; // <-- import the module
import { EmbedVideo } from 'ngx-embed-video';
import { AppRoutingModule } from './app-routing.module';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { LibraryService } from './shared/library.service';
import { FormsModule } from '@angular/forms';
import { Ng2OdometerModule } from 'ng2-odometer'; // <-- import the module
import {TabsModule} from 'ngx-tabset';

import { HomeComponent } from './home/home.component';
import { FaqComponent } from './faq/faq.component';
import { PublicProcurementPolicyComponent } from './public-procurement-policy/public-procurement-policy.component';
import { VendorInformationComponent } from './vendor-information/vendor-information.component';
import { InvestorsComponent } from './investors/investors.component';
import { ActiveTendersComponent } from './active-tenders/active-tenders.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { VigilanceComponent } from './vigilance/vigilance.component';
import { ContactInformationComponent } from './contact-information/contact-information.component';
import { PressReleaseComponent } from './press-release/press-release.component';
import { JobComponent } from './job/job.component';
import { NewsComponent } from './news/news.component';
import { PhotoGalleryComponent } from './photo-gallery/photo-gallery.component';
import { ProjectsProgressComponent } from './projects-progress/projects-progress.component';
import { ProjectsCompletedComponent } from './projects-completed/projects-completed.component';
import { RtiComponent } from './rti/rti.component';
import { PolicyComponent } from './policy/policy.component';
import { CsrComponent } from './csr/csr.component';
import { MouComponent } from './mou/mou.component';
import { CompanyStructureComponent } from './company-structure/company-structure.component';
import { SpvsComponent } from './spvs/spvs.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { ManagementBoardComponent } from './management-board/management-board.component';
import { ManagementBoardDetailsComponent } from './management-board-details/management-board-details.component';
import { DisqualifiedContractorsComponent } from './disqualified-contractors/disqualified-contractors.component';
import { ContractsAwardedComponent } from './contracts-awarded/contracts-awarded.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { EmployeeLoginComponent } from './employee-login/employee-login.component';
import { NewsDetailsComponent } from './news-details/news-details.component';
import { AnnouncementsDetailsComponent } from './announcements-details/announcements-details.component';
import { DataTablesModule } from 'angular-datatables';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { LightboxModule } from 'ngx-lightbox';
import { NgImageSliderModule } from 'ng-image-slider';
import { RouterModule } from '@angular/router';
import { ScrollToBottomDirective } from './shared/scroll-to-bottom.directive';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NewsDetailsComponent,
    FaqComponent,
    PublicProcurementPolicyComponent,
    AnnouncementsDetailsComponent,
    VendorInformationComponent,
    InvestorsComponent,
    ActiveTendersComponent,
    AboutUsComponent,
    EmployeeLoginComponent,
    VigilanceComponent,
    ContactInformationComponent,
    PressReleaseComponent,
    JobComponent,
    NewsComponent,
    PhotoGalleryComponent,
    ProjectsProgressComponent,
    ProjectsCompletedComponent,
    RtiComponent,
    PolicyComponent,
    CsrComponent,
    MouComponent,
    ScrollToBottomDirective,
    CompanyStructureComponent,
    SpvsComponent,
    ProjectDetailsComponent,
    ManagementBoardComponent,
    ManagementBoardDetailsComponent,
    DisqualifiedContractorsComponent,
    ContractsAwardedComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgxPaginationModule,
    FormsModule,
    LightboxModule,
    NgImageSliderModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    DataTablesModule,
    CarouselModule, 
    Ng2OdometerModule.forRoot(),
    TabsModule.forRoot(),
    HttpClientModule, EmbedVideo.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    // {
    //   provide: RECAPTCHA_SETTINGS,
    //   useValue: {
    //     siteKey: environment.recaptcha.siteKey,
    //   } as RecaptchaSettings,
    // },
    LibraryService,
    DatePipe,
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
