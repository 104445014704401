import { Component, OnInit } from '@angular/core';
import { LibraryService } from '../shared/library.service';
import { environment as ENV } from '../../environments/environment.prod';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
@Component({
	selector: 'app-disqualified-contractors',
	templateUrl: './disqualified-contractors.component.html',
	styleUrls: ['./disqualified-contractors.component.css']
})
export class DisqualifiedContractorsComponent implements OnInit {
  public baseUrl: string = ENV.BASE_URL;
  public disqualifiedData: any;
  public lang:any = sessionStorage.getItem('lang');
  public DisqualifiedContractorsData: any;
  public DisqualifiedContractorsDataPdf: any = [];
  yt_iframe_html: any;
  public trustedDashboardUrl : SafeUrl;
  constructor(
    private libraryService: LibraryService,
    private sanitizer: DomSanitizer
	) {

	}


	ngOnInit() {
    this.libraryService.getDisqualified(this.lang).subscribe(result => {
      this.disqualifiedData = result;
      this.disqualifiedData.forEach(element => {
        var url = this.baseUrl +"uploads/disqualified/"+ element.disqualified_file;
        this.trustedDashboardUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        // console.log(url);
        // return false;
        this.yt_iframe_html = url;

        var ngxg = {
          id: element.id,
          pdfSrc:this.trustedDashboardUrl,
          pdf:element.disqualified_file
        };

        this.disqualifiedData.push(ngxg);
      });
    });
  }

}
