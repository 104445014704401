import { Component, OnInit } from '@angular/core';
import { LibraryService } from '../shared/library.service';
import { environment as ENV } from '../../environments/environment.prod';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { EmbedVideoService } from 'ngx-embed-video';

@Component({
	selector: 'app-news-details',
	templateUrl: './news-details.component.html',
	styleUrls: ['./news-details.component.css']
})
export class NewsDetailsComponent implements OnInit {
  public baseUrl: string = ENV.BASE_URL;
  public lang:any = sessionStorage.getItem('lang');
  public newsDetailsData: any;
  public RelatedData:any;
  public heading:any;
  public short_desc:any;
  public full_desc:any;
  public restdescription1:any;
  public restdescription2:any;
  public img:any;
  public files:any;
  public news_date:any;
  public yt_iframe_html:any;
  public imageObject: any[] = [];
  public imgarray:any;
  constructor(
    private libraryService: LibraryService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private embedService: EmbedVideoService,
    private router: Router
	) {

	}


	ngOnInit() {
    let newsId = this.route.snapshot.paramMap.get('id');
    this.libraryService.getNewsDetails(this.lang,newsId).subscribe(result => {
      console.log("start");
      console.log(result);
      console.log("end");
      this.newsDetailsData = result;
      this.heading = result.heading;
      this.short_desc = result.short_desc;
      this.full_desc = result.full_desc;
      this.restdescription1=this.full_desc.substring(0,4000); 
      this.restdescription2=this.full_desc.substring(4386,this.full_desc.length); 
      this.img = result.img;
      this.files = result.files;
      this.news_date = result.news_date;
      if(this.newsDetailsData.type == 'Video'){
        this.yt_iframe_html = this.embedService.embed(this.newsDetailsData.img,{
          attr: { width: 560, height: 315 }
        });
      }
      this.imgarray = this.newsDetailsData.img;
      this.imgarray.forEach(element => {
        const image = this.baseUrl+'uploads/news/'+element;
      const thumbImage=this.baseUrl+'uploads/news/'+element;
      const album = {
        image:image,
        thumbImage:thumbImage
      };
      this.imageObject.push(album);
     });
    });
  }

}
