import { Component, OnInit } from '@angular/core';
import { LibraryService } from '../shared/library.service';
import { environment as ENV } from '../../environments/environment.prod';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
	selector: 'app-announcements-details',
	templateUrl: './announcements-details.component.html',
	styleUrls: ['./announcements-details.component.css']
})

export class AnnouncementsDetailsComponent implements OnInit {
  public baseUrl: string = ENV.BASE_URL;
  public lang:any = sessionStorage.getItem('lang');
  public newsDetailsData: any;
  public RelatedData:any;
  public heading:any;
  public short_desc:any;
  public full_desc:any;
  public restdescription1:any;
  public restdescription2:any;
  public img:any;
  public announce_date:any;
  constructor(
    private libraryService: LibraryService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router
	) {

	}


	ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    this.libraryService.getAnnouncementsDetails(this.lang,id).subscribe(result => {
      this.newsDetailsData = result;
      this.heading = result.heading;
      this.short_desc = result.short_desc;
      this.full_desc = result.full_desc;
      this.restdescription1=this.full_desc.substring(0,4000); 
      this.restdescription2=this.full_desc.substring(4386,this.full_desc.length);
      this.img = result.img;
      this.announce_date = result.announce_date;
    });

  }

}
