import { Component, OnInit } from '@angular/core';
import { LibraryService } from '../shared/library.service';
import { environment as ENV } from '../../environments/environment.prod';
@Component({
	selector: 'app-company-structure',
	templateUrl: './company-structure.component.html',
	styleUrls: ['./company-structure.component.css']
})
export class CompanyStructureComponent implements OnInit {
  public baseUrl: string = ENV.BASE_URL;
  public CompanyStructureData: any;
  public PUIData: any;
  public SubsidiariesData: any;
  public lang:any = sessionStorage.getItem('lang');
  public isloded:boolean=false;
  public PIUNAME: string;
  public TBLDATA: string;
  dtOptions: any = {};

  constructor(
    private libraryService: LibraryService,
	) {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthMenu : [5, 10, 25],
      processing: true
    };
	}


	ngOnInit() {
    this.libraryService.getPUIOffice(this.lang).subscribe(result => {
      this.PUIData = result;  
    //   this.TBLDATA='';    
    //   this.PUIData.forEach(element => {
    //     this.TBLDATA +='<tr role="row" class="odd">';
    //     if(this.PIUNAME == element.name){
    //       this.TBLDATA +='<td rowspan="2">'+element.name+'</td><td>'+element.units+'</td><td>'+element.cpms+'</td><td><div innerHTML="'+element.projects+'"></div></td><td><div innerHTML="'+element.address+'"></div></td> ';
    //     }else{
    //       this.TBLDATA +='<td>'+element.units+'</td><td>'+element.cpms+'</td><td><div innerHTML="'+element.projects+'"></div></td><td><div innerHTML="'+element.address+'"></div></td> ';
    //     }
    //     this.TBLDATA +='</tr>';
    //     this.PIUNAME = element.name;
    // });
     console.log(this.PUIData);
    // console.log(this.TBLDATA);
    var rowspan = 1;
    this.PUIData.forEach(element => {
      if(this.PIUNAME == element.name){
        rowspan ++;
        element.address = null;
        element.phone = rowspan;
      }else{
        rowspan = 1;
      }
      this.PIUNAME = element.name;
  });
      this.isloded = true;
      // console.log(this.PUIData);
    });

    this.libraryService.getSubsidiaries(this.lang).subscribe(result => {
      this.SubsidiariesData = result;
    });

  }

}
