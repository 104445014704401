import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { FaqComponent } from './faq/faq.component';
import { PublicProcurementPolicyComponent } from './public-procurement-policy/public-procurement-policy.component';
import { VendorInformationComponent } from './vendor-information/vendor-information.component';
import { InvestorsComponent } from './investors/investors.component';
import { ActiveTendersComponent } from './active-tenders/active-tenders.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { VigilanceComponent } from './vigilance/vigilance.component';
import { ContactInformationComponent } from './contact-information/contact-information.component';
import { PressReleaseComponent } from './press-release/press-release.component';
import { JobComponent } from './job/job.component';
import { NewsComponent } from './news/news.component';
import { PhotoGalleryComponent } from './photo-gallery/photo-gallery.component';
import { ProjectsProgressComponent } from './projects-progress/projects-progress.component';
import { ProjectsCompletedComponent } from './projects-completed/projects-completed.component';
import { RtiComponent } from './rti/rti.component';
import { PolicyComponent } from './policy/policy.component';
import { CsrComponent } from './csr/csr.component';
import { MouComponent } from './mou/mou.component';
import { SpvsComponent } from './spvs/spvs.component';
import { CompanyStructureComponent } from './company-structure/company-structure.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { ManagementBoardComponent } from './management-board/management-board.component';
import { ManagementBoardDetailsComponent } from './management-board-details/management-board-details.component';
import { DisqualifiedContractorsComponent } from './disqualified-contractors/disqualified-contractors.component';
import { ContractsAwardedComponent } from './contracts-awarded/contracts-awarded.component';
import { EmployeeLoginComponent } from './employee-login/employee-login.component';
import { NewsDetailsComponent } from './news-details/news-details.component';
import { AnnouncementsDetailsComponent } from './announcements-details/announcements-details.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'mou', component: MouComponent },
  { path: 'spvs', component: SpvsComponent },
  { path: 'employee-login/:id/:title', component: EmployeeLoginComponent },
  { path: 'contracts-awarded', component: ContractsAwardedComponent },
  { path: 'disqualified-contractors', component: DisqualifiedContractorsComponent },
  { path: 'management-board', component: ManagementBoardComponent },
  { path: 'announcements-details/:id', component: AnnouncementsDetailsComponent },
  { path: 'news-details/:id', component: NewsDetailsComponent },
  { path: 'project-details/:id/:category', component: ProjectDetailsComponent },
  { path: 'management-board-details/:id', component: ManagementBoardDetailsComponent },
  { path: 'company-structure', component: CompanyStructureComponent },
  { path: 'csr', component: CsrComponent },
  { path: 'policy', component: PolicyComponent },
  { path: 'rti', component: RtiComponent },
  { path: 'public-procurement-policy', component: PublicProcurementPolicyComponent },
  { path: 'vendor-information', component: VendorInformationComponent },
  { path: 'investor', component: InvestorsComponent },
  { path: 'active-tenders', component: ActiveTendersComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'vigilance', component: VigilanceComponent },
  { path: 'press-release', component: PressReleaseComponent },
  { path: 'job', component: JobComponent },
  { path: 'news', component: NewsComponent },
  { path: 'photo-gallery', component: PhotoGalleryComponent },
  { path: 'projects-progress', component: ProjectsProgressComponent },
  { path: 'projects-completed', component: ProjectsCompletedComponent },
  { path: 'contact-information', component: ContactInformationComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }),
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
