import { Component } from '@angular/core';
import { LibraryService } from './shared/library.service';
import { environment as ENV } from '../environments/environment.prod';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location, LocationStrategy } from '@angular/common';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
declare const myhello:any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'rvnl';
  isblock:string='';
  public siteUrl:any;
  public baseUrl: string = ENV.BASE_URL;
  public headerLogo: any;
  public footerLogo: any;
  public mainMenu: any;
  public topBarSocialLinks: any;
  public topBarStockPrice: any;
  public getTopBarLoginLinks: any;
  public getTopBarContactUs: any;
  public getTopBarLinks: any;
  public footerSocialLinks:any;
  public FooterUsefullLinks:any;
  public FooterExternalLinks:any;
  public FooterContactUs:any;
  public store:any;
  public lang:any;
  public topSearchDisplay:any = 'hide';
  public currentDate:any;
  public isBodyShow:boolean=true;
  boturl:SafeUrl;
  StockLinks:SafeUrl;
  colapse: boolean[] = [false];

  constructor(
    private libraryService: LibraryService,
    private SpinnerService: NgxSpinnerService,
    private sanitizer: DomSanitizer,
    private location: Location, 
    private locationStrategy: LocationStrategy
  ) {
    this.siteUrl = window.location.protocol+"//"+window.location.host;
    
    this.SpinnerService.show();
    if(sessionStorage.length == 0){
      sessionStorage.setItem('lang', 'en');
    }
    // sessionStorage.removeItem('lang');
    // console.log(sessionStorage.length);
  }

  openbot(){ 
    
    var is_safari = navigator.userAgent.indexOf("Safari") > -1;
    console.log(is_safari);
    var is_chrome = navigator.userAgent.indexOf('Chrome') > -1;
    if ((is_chrome) && (is_safari)) {is_safari = false; console.log("Safari false");}  
    if (is_safari) {
      var viewportwidth = document.documentElement.clientWidth;
      var viewportheight = document.documentElement.clientHeight;
      const myWindow = window.open("https://acebot.misfitsprojects.com/Bot/rvnl.html?hddclientid=RVNL-1669961049","","width=300,height="+(viewportheight+200)+",left="+(viewportwidth+400)+",top=0");
    }else{
      this.boturl= this.sanitizer.bypassSecurityTrustResourceUrl('https://acebot.misfitsprojects.com/Bot/rvnl.html?hddclientid=RVNL-1669961049'); 
      $('.fabs').toggleClass('is-visible');
      $('.fab').toggleClass('is-visible');
      $('#vikashdiv').hide();
      $('#chat_converse').css('display', 'none');
      $('#chat_body').css('display', 'none');
      $('#chat_form').css('display', 'none');
      $('.chat_login').css('display', 'none');
      $('.chat_fullscreen_loader').css('display', 'block');
      $('#chat_fullscreen').css('display', 'block');
      $('.chat').hide();
      //Toggle chat and links
      setTimeout(function() {
      $('#chat_fullscreen').css('display', 'block');
      $('.chat').addClass('is-visible');
      $('.chat').show();
      $('.fab').addClass('is-visible');
      $('.chat_fullscreen_loader').css('display', 'block');
      $('#chat_fullscreen').css('display', 'block');
      $('.fullscreen-container').show();
      }, 10);    
      jQuery('#mCSB_1_container').html('');
    }
  }

  openbot2(){   
    $('.fabs').toggleClass('is-visible');
    $('.fab').toggleClass('is-visible');
    $('#vikashdiv2').hide();
    $('#chat_converse').css('display', 'none');
    $('#chat_body').css('display', 'none');
    $('#chat_form').css('display', 'none');
    $('.chat_login').css('display', 'none');
    $('.chat_fullscreen_loader').css('display', 'block');
    $('#chat_fullscreen').css('display', 'block');
    $('.chat').hide();
    //Toggle chat and links
    setTimeout(function() {
    $('#chat_fullscreen').css('display', 'block');
    $('.chat').addClass('is-visible');
    $('.chat').show();
    $('.fab').addClass('is-visible');
    $('.chat_fullscreen_loader').css('display', 'block');
    $('#chat_fullscreen').css('display', 'block');
    $('.fullscreen-container').show();
    }, 10);    
  }

  closebot(){
    $('.fullscreen-container').hide();
    $("#lang").text("En");
    $('#lang').attr('data-lang','en-IN');
    $("#footer2").hide();
    $("#footer1").show();
    $("#textmode").show();
    $("#voicemode").hide(); 
    $('.chat').toggleClass('is-visible');
    $('.fab').toggleClass('is-visible');
    $('.chat').hide();
    $('#vikashdiv2').show();
  }

  viewStockData(topBarStockPrice){
    let url = topBarStockPrice.link;
    this.StockLinks = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  langView(lang){
    sessionStorage.setItem('lang', lang);
    window.location.reload();
  }
  
  search(){
    this.isBodyShow = false;
  }

  searchTopBar(){
    this.topSearchDisplay = 'show';
  }

  searchTopBarClose(){
    this.topSearchDisplay = 'hide';
    if(this.isBodyShow == false){
      window.location.reload();
    }
  }

  toggle(id) {
		if (!this.colapse[id]) {
		  this.colapse[id] = true;
		}
		else {
		  this.colapse[id] = !this.colapse[id];
		}
	}

  mouseEnter(){ 
    this.isblock='d-block';
  }
  mouseLeave(){
    this.isblock='';
  }
  ngOnInit() {
    this.lang = sessionStorage.getItem('lang');

    this.libraryService.getLastUpdatedDate(this.lang).subscribe(result => {
      this.currentDate = result;
    });

    this.libraryService.getHeaderLogo(this.lang).subscribe(result => {
      this.headerLogo = result;
    });

    this.libraryService.getFooterLogo(this.lang).subscribe(result => {
      this.footerLogo = result;
    });

    this.libraryService.getMainMenu(this.lang).subscribe(result => {
      this.mainMenu = result;
      this.SpinnerService.hide();
    });

    this.libraryService.getTopSocialLinks(this.lang).subscribe(result => {
      this.topBarSocialLinks = result;
    });

    this.libraryService.getTopStockPrice(this.lang).subscribe(result => {
      this.topBarStockPrice = result;
    });

    this.libraryService.getTopLoginLinks(this.lang).subscribe(result => {
      this.getTopBarLoginLinks = result;
    });

    this.libraryService.getTopContactUs(this.lang).subscribe(result => {
      this.getTopBarContactUs = result;
    });

    this.libraryService.getTopLinks(this.lang).subscribe(result => {
      this.getTopBarLinks = result;
    });

    this.libraryService.getFooterSocialLinks(this.lang).subscribe(result => {
      this.footerSocialLinks = result;
    });

    this.libraryService.getFooterUsefullLinks(this.lang).subscribe(result => {
      this.FooterUsefullLinks = result;
    });

    this.libraryService.getFooterContactUs(this.lang).subscribe(result => {
      
      result[0].email= (result[0].email.replace('@', '[at]')).replace('.', '[dot]');
      this.FooterContactUs = result;
    });

    this.libraryService.getFooterExternalLinks(this.lang).subscribe(result => {
      this.FooterExternalLinks = result;
    });

  }
}
