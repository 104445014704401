import { Component, OnInit } from '@angular/core';
import { LibraryService } from '../shared/library.service';
import { environment as ENV } from '../../environments/environment.prod';
import { Location, LocationStrategy } from '@angular/common';

@Component({
	selector: 'app-management-board',
	templateUrl: './management-board.component.html',
	styleUrls: ['./management-board.component.css']
})
export class ManagementBoardComponent implements OnInit {
  public baseUrl: string = ENV.BASE_URL;
  public siteUrl:any;
  public ManagementBoardData: any;
  public lang:any = sessionStorage.getItem('lang');

  constructor(
    private libraryService: LibraryService,
    private location: Location, 
    private locationStrategy: LocationStrategy
	) {
    this.siteUrl = this.locationStrategy.getBaseHref();
    if(localStorage.getItem("backdata")=="backdata"){
      window.location.reload();
      localStorage.setItem("backdata",null);
    }
	}

  trackBydata(index:number,data:any){
    return data.id;
  }

	ngOnInit() {
    this.libraryService.getManagementBoard(this.lang).subscribe(result => {
      this.ManagementBoardData = result;
    });

  }

}
